// eslint-disable-next-line
export const isObject = (obj: any) => {
	return obj === Object(obj) && !Array.isArray(obj) && typeof obj !== 'function';
};

export const isEmptyObject = (object: Record<string, any>) => {
	return Object.keys(object).length === 0;
};

export const camelToUnderscore = (key: string): string => {
	return key.replace(/([A-Z])/g, '_$1').toLowerCase();
};

export const underscroreToCamel = (key: string): string => {
	return key.replace(/([-_][a-z])/gi, ($1: string) => {
		return $1.toUpperCase().replace('-', '').replace('_', '');
	});
};

// eslint-disable-next-line
export const keysToSnakeCase = (
	origin: Record<string, any>
	// eslint-disable-next-line
): Record<string, any> => {
	// eslint-disable-next-line
	const snaked: Record<string, any> = {};

	Object.entries(origin).forEach((item) => {
		snaked[camelToUnderscore(item[0])] = item[1];
	});

	return snaked;
};

// eslint-disable-next-line
export const keysToCamelCase = (origin: Record<string, any>) => {
	if (isObject(origin)) {
		const cameled: Record<string, any> = {};

		Object.keys(origin).forEach((k) => {
			cameled[underscroreToCamel(k)] = keysToCamelCase(origin[k]);
		});

		return cameled;
	}
	return origin;
};

export const objectToFormData = (object: Record<string, any>) => {
	let data = new FormData();

	for (const key in object) {
		if (object.hasOwnProperty(key)) {
			data.append(key, object[key]);
		}
	}

	return data;
};

export const deeplyApplyKeyTransform = <T>(obj: any, transform: (key: string) => string) => {
	const ret: { [key: string]: any } = Array.isArray(obj) ? [] : {};
	Object.keys(obj).forEach((key) => {
		if (obj[key] != null && typeof obj[key] === 'object') {
			ret[transform(key)] = deeplyApplyKeyTransform(obj[key], transform);
		} else {
			ret[transform(key)] = obj[key];
		}
	});
	return ret as T;
};

export const groupBy = <T, K extends keyof any>(list: T[], getKey: (item: T) => K) =>
	list.reduce((acc, item) => {
		const group = getKey(item);
		if (!acc[group]) acc[group] = [];
		acc[group].push(item);
		return acc;
	}, {} as Record<K, T[]>);

export const toFormData = (object: Record<string, any>): FormData => {
	let data = new FormData();

	for (const key in object) {
		if (object.hasOwnProperty(key)) {
			data.append(key, object[key]);
		}
	}

	return data;
};

export const stripUndefined = (obj: any) => {
	if (isEmptyObject(obj)) {
		return obj;
	}
	const copy: Record<string, any> = { ...obj };
	for (const [k, v] of Object.entries(copy)) {
		if (typeof v === 'undefined') delete copy[k];
	}
	return copy;
};

import React, { FC, useMemo } from 'react';

interface SpacerProps {
	value?: number;
	className?: string;
}

export const Spacer: FC<SpacerProps> = React.memo(({ className, value = 0 }) => {
	const css = useMemo(() => ({ marginBottom: value }), [value]);
	return <div className={className} style={css} />;
});
